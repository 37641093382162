<template>
  <div class="wrap">
   <Loading v-show="$store.state.isLoading"/>
   <van-list v-show="!$store.state.isLoading">
      <!-- <van-cell v-for="(item,id) in list" :key="id" :title="item.title" is-link /> -->
      <van-col span="22" offset="1" v-for="(item,id) in list" :key="id" style="margin-top:10px;border-radius:5px;">
        <van-cell is-link :center="true" :title="item.contract_number" :label="item.vin" :to="{ path: '/rentPlanPayDetail', query: { contractNumber: item.contract_number }}">
          
        </van-cell>
      </van-col>
    </van-list>
  </div>
</template>
<script>
  import {rentPlanContract,getBindStatus,getOpenId} from '@/server'
  import { Toast } from 'vant';
  import Loading from "@/components/Loading";
  import {setCookie,getCookie,domainUrl} from '@/lib/utils';
  export default {
    components: {
      Loading
    },
  data() {
    return {
      cardId:this.$store.state.cardId,
      // openId:localStorage.getItem("openId") ||this.$store.state.openId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      accessToken:this.$store.state.accessToken,
      list: [],
      finished: false,
    };
  },
  mounted(){
    sessionStorage.setItem('key', 2);
    let that = this;
    window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
      if(sessionStorage.getItem('key') == 2) {
        that.$router.push({ path: '/personalCenter' });
      }
      // window.history.pushState('forward', null, '/personalCenter');
    }, false);
  },
  created(){
    //如果有openid
    if(this.openId){
      getOpenId({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          this.$store.commit('bindStatus', res.bind_status);
          localStorage.setItem("bindStatus", res.bind_status);
          localStorage.setItem("accessToken", res.access_token);
          this.$store.commit('accessToken', res.access_token);
          // localStorage.setItem("openId", res.openid);
          setCookie('openId',res.openid);
          this.$store.commit('openId', res.openid);
          this.$store.commit('cardId', res.card_id);
          localStorage.setItem("cardId", res.card_id);
          this.accessToken = res.access_token;
          this.cardId = res.card_id;
          if(res.bind_status !=='1'){
            this.$router.push({ path: '/login?redirect=/rentPlanCtr' });
          }else{
            this.getDetail();
          }
        }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
      })
    } else{
      if(!this.code) {
        this.$store.commit('bindStatus', '1');
        localStorage.setItem("bindStatus", '1');
        let uiCode = encodeURIComponent(domainUrl+'/rentPlanCtr');
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
      }else{//获取code
        getOpenId({percode:this.code}).then(res=>{
          if(res.wx_code=='0'){
            this.$store.commit('bindStatus', res.bind_status);
            localStorage.setItem("bindStatus", res.bind_status);
            this.$store.commit('accessToken', res.access_token);
            localStorage.setItem("accessToken", res.access_token);
            this.$store.commit('openId', res.openid);
            // localStorage.setItem("openId", res.openid);
            setCookie('openId',res.openid)
            this.$store.commit('cardId', res.card_id);
            localStorage.setItem("cardId", res.card_id);
            this.accessToken = res.access_token;
            this.cardId = res.card_id;
            if(res.bind_status !=='1'){
              this.$router.push({ path: '/login?redirect=/rentPlanCtr' });
            }else{
              this.getDetail();
            }
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        });
      }
    }
  },
  methods: {
    getDetail(){
      this.list = this.$store.state.dataListTotal;
      // const params = {
      //   access_token:this.accessToken,
      //   card_id:this.cardId
      // }
      // rentPlanContract(params).then(res=>{
      //   if(res.status=='200'){
      //     this.list = res.data
      //   }else{
      //     Toast.fail({
      //       message: res.msg,
      //       duration:'3000'
      //     });
      //   }
      // })
    },
  },
};
</script>
<style scoped>
.wrap{
    /* padding: 20px 0px;  */
    min-height: 100vh !important;
    overflow: hidden;
    background: #F8F8F8;
  }
  .custom-title {
    display:block;
    /* vertical-align: middle; */
  }
  .custom-date {
    display:block;
    color: #999999;
    font-size: 12px;
  }
  .custom-status{
    font-size: 11px;
  }
</style>
<style>
#app{
  background: #F8F8F8 !important;
}
</style>